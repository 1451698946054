<script lang="ts">
	import Paginator from "../Paginator.svelte";
	import DecoratedHeadline from "../DecoratedHeadline.svelte";
	import type { ActionReturn } from "svelte/action";
	import * as Glide from "@glidejs/glide";
	import { disableGlidePerSlideAmount } from "../../utils/disableGlidePerSlideAmount.js";
	import { referencesPathMap } from "../../../../core/schema/paths/referencesPathMap.js";
	import { getGlobalSettings } from "../../getGlobalSettings.js";
	import Link from "../Link.svelte";
	import type { PublishedCaseStudy } from "../../../../core/schema/CaseStudy.js";
	import DynamicPicture from "../Picture/DynamicPicture.svelte";
	import { getReferencePath } from "../../../../core/schema/paths/getReferencePath.js";
	import Content from "../Content.svelte";
	import { caseStudyCategoryNameMap } from "../../../../core/schema/names/caseStudyCategoryNameMap.js";
	import { CaseStudyCategory } from "../../../../core/schema/CaseStudyCategory.js";
	import { nbspify } from "../../../../core/utils/nbspify.js";

	export let references: PublishedCaseStudy[];

	const { TENANT_LOCALE } = getGlobalSettings();

	let currentSlide = 0;

	function glide(element: HTMLDivElement): ActionReturn {
		const glideOptions = {
			type: "carousel",
			startAt: 0,
			focusAt: "center",
			perView: 1,
			autoplay: false,
			hoverpause: false,
			animationDuration: 650,
			animationTimingFunc: "ease-in-out",
			keyboard: true,
			cloningRatio: 2,
			gap: 0,
		} satisfies Partial<Glide.Options>;

		// eslint-disable-next-line new-cap
		const glide = new Glide.default(element, glideOptions).mount();
		glide.on("run", () => {
			currentSlide = glide.index;
		});
		glide.on("resize", () => {
			disableGlidePerSlideAmount(glide, element);
		});

		return {
			destroy(): void {
				glide.destroy();
			},
		};
	}

	$: currentReference = references[currentSlide];
	$: link = currentReference && getReferencePath(currentReference);
</script>

{#if currentReference}
	<div class="relative mb-32 mt-20 w-full lg:mb-64 lg:mt-32">
		<div class="flex w-full flex-col px-2 md:w-fit md:px-6 lg:w-full">
			<DecoratedHeadline>Reference</DecoratedHeadline>

			<div class="relative flex h-[27rem] flex-col md:h-[32rem] lg:mt-16 lg:flex-row" use:glide>
				<span
					class="font-quicksand group-hover:text-tertiary text-gray ml-auto mt-5 w-12 pr-4 text-end text-[4.375rem] leading-none lg:ml-0 lg:mt-0 lg:pr-0 lg:text-[10rem]"
				>
					{currentSlide + 1}
				</span>
				<div class="w-full lg:mx-auto lg:w-fit">
					<div class="mt-5 flex flex-col lg:mt-10 xl:w-[56.25rem]">
						<div
							class="glide__track ml-auto w-full max-w-[46.125rem] overflow-hidden lg:h-[30.25rem] lg:w-[80%] xl:w-[46.125rem]"
							data-glide-el="track"
						>
							<div class="glide__slides flex">
								{#each references as reference}
									<div class="glide__slide">
										<DynamicPicture
											image={reference.thumbnailImage}
											width={738}
											height={484}
											bucket="storage"
											alt={reference.title[TENANT_LOCALE]}
											imgClass="h-[12.5rem] md:h-[17.625rem] object-cover w-full pr-4 min-[425px]:pr-0 lg:h-[30.25rem]"
											class="relative"
											sourcesets={{
												"<md": [0, 200],
												"<lg": [0, 282],
												"<xl": [0, 484],
											}}
										/>
									</div>
								{/each}
							</div>
						</div>
						<div
							class="absolute bottom-0 left-4 right-0 z-20 flex w-[calc(100%-16px)] translate-y-1/2 flex-col md:left-auto lg:relative lg:bottom-0 lg:left-0 lg:w-full lg:translate-y-0 lg:flex-row"
						>
							<div
								class="text-2xs bg-primary ml-auto flex h-[1.375rem] w-fit items-center justify-center px-6 text-white lg:hidden"
							>
								{nbspify(
									caseStudyCategoryNameMap[references[currentSlide]?.caseStudyCategory ?? CaseStudyCategory.Agv][
										TENANT_LOCALE
									],
								)}
							</div>
							<div
								class="border-gray bottom-0 left-0 block h-max max-h-[11rem] min-h-[9.188rem] border bg-white p-6 lg:absolute lg:w-[60%] lg:border-b"
							>
								<h3 class="text-primary font-fustat mb-2 text-base font-extrabold lg:text-[1.5rem]">
									{nbspify(references[currentSlide]?.title[TENANT_LOCALE])}
								</h3>
								<p class="line-clamp-3 text-xs text-gray-400">
									<Content content={references[currentSlide]?.perex[TENANT_LOCALE] ?? ""} contentClass={false} />
								</p>
							</div>
							<Paginator
								class="ml-auto hidden self-end border-l-0 lg:flex lg:w-[40%]"
								itemsLength={references.length}
								{currentSlide}
								visibleSlides={[currentSlide + 1]}
							/>
							<div class="mr-auto flex lg:hidden">
								<Link asButton variant="primary" class="w-[7.5rem]" href="/{link}">Zjistit více</Link>

								<Link
									asButton
									variant="secondary"
									class="w-[7.5rem] border-t-0 hover:border"
									href="/{referencesPathMap[TENANT_LOCALE]}"
								>
									{nbspify("Více referencí")}
								</Link>
							</div>
							<Paginator
								class="mx-auto mt-6 w-full max-w-[14.875rem] lg:hidden"
								itemsLength={references.length}
								visibleSlides={[currentSlide + 1]}
								{currentSlide}
								customBulletCount={1}
							/>
						</div>
					</div>

					<div class="hidden lg:flex">
						<Link asButton variant="primary" class="w-[7.5rem] sm:w-[13.75rem]" href="/{link}">Zjistit více</Link>

						<Link
							asButton
							variant="secondary"
							class="hover:border-t-tertiary w-[7.5rem] border-t-transparent sm:w-[13.75rem]"
							href="/{referencesPathMap[TENANT_LOCALE]}"
						>
							{nbspify("Více referencí")}
						</Link>
					</div>
				</div>
			</div>
		</div>
	</div>
{/if}
